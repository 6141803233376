<template>
    <div class="block relative" :class="{'row-span-3': isEditing, 'row-span-1': !isEditing}">
        <router-link v-if="!isEditing" :to="{name: 'pathBoard', params: {boardID: board.JID}}" tag="div" class="flex flex-col rounded-lg shadow-lg overflow-hidden cursor-pointer">
            <!--        <div class="flex-shrink-0">-->
            <!--            <img class="h-48 w-full object-cover" src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80" alt="">-->
            <!--        </div>-->
            <div class="flex-1 bg-white px-6 py-3 flex flex-col justify-between">
                <div class="flex-1">
                    <!--                <p class="text-sm font-medium text-indigo-600">-->
                    <!--                    <a href="#" class="hover:underline">-->
                    <!--                        Article-->
                    <!--                    </a>-->
                    <!--                </p>-->
                    <div class="block mt-2">
                        <p class="text-xl font-semibold text-gray-900">
                            {{ board.name }}
                        </p>
                        <p class="mt-3 text-base text-gray-500">
                            {{ board.description }}
                        </p>
                    </div>
                </div>
                <!--            <div class="mt-6 flex items-center">-->
                <!--                <div class="flex-shrink-0">-->
                <!--                    <a href="#">-->
                <!--                        <span class="sr-only">Roel Aufderehar</span>-->
                <!--                        <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">-->
                <!--                    </a>-->
                <!--                </div>-->
                <!--                <div class="ml-3">-->
                <!--                    <p class="text-sm font-medium text-gray-900">-->
                <!--                        <a href="#" class="hover:underline">-->
                <!--                            Roel Aufderehar-->
                <!--                        </a>-->
                <!--                    </p>-->
                <!--                    <div class="flex space-x-1 text-sm text-gray-500">-->
                <!--                        <time datetime="2020-03-16">-->
                <!--                            Mar 16, 2020-->
                <!--                        </time>-->
                <!--                        <span aria-hidden="true">-->
                <!--                            &middot;-->
                <!--                        </span>-->
                <!--                        <span>-->
                <!--                            6 min read-->
                <!--                        </span>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--            </div>-->
            </div>
        </router-link>

        <div v-if="isEditing" class="bg-white overflow-hidden shadow rounded-lg cursor-pointer group ">
            <div class="px-2 py-3 sm:p-4">
                <!-- This example requires Tailwind CSS v2.0+ -->

                <div class="space-y-6 sm:space-y-5">

                    <div class="sm:col-span-4">
                        <label for="newBoardName" class="block text-sm font-medium text-gray-700">
                            Board Name
                        </label>
                        <div class="mt-1 flex rounded-md shadow-sm">
                            <input v-model="newDetails.name" type="text" name="newBoardName" id="newBoardName" autocomplete="username" class="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300">
                        </div>
                    </div>

                    <div class="sm:col-span-6">
                        <label for="newBoardDescription" class="block text-sm font-medium text-gray-700">
                            Description
                        </label>
                        <div class="mt-1">
                            <textarea v-model="newDetails.description" id="newBoardDescription" name="newBoardDescription" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
                        </div>
                        <p class="mt-2 text-sm text-gray-500">Write a short description of your new board (optional)</p>
                    </div>


                    <div class="mt-8 border-gray-200 pt-5">
                        <div class="flex justify-end">
                                <span class="inline-flex rounded-md shadow-sm">
                                    <button type="button" @click="cancelUpdateBoardDetails" class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                                      Cancel
                                    </button>
                                </span>
                            <span class="ml-3 inline-flex rounded-md shadow-sm">
                                    <button @click="updateBoardDetails" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                      Update
                                    </button>
                                </span>
                        </div>
                    </div>
                </div>

                <!-- Content goes here -->
            </div>
        </div>

        <div class="absolute right-1 top-1">
            <div v-if="canEdit">
<!--                <button v-if="isEditing" @click="finishEditing" class="text-jiruto-zotMain hover:text-jiruto-zotDarker rounded-full bg-gray-200 p-2 hover:bg-gray-100  border-0">-->
<!--                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>-->
<!--                </button>-->

                <button v-if="!isEditing" @click="startEditing" class="text-jiruto-zotMain hover:text-jiruto-zotDarker rounded-full bg-gray-200 p-2 hover:bg-gray-100  border-0">
                    <svg class="w-6 h-6 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/></svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import * as axios from "@/axios-auth";

    export default {
        name: "BoardCard",
        props: {
            board: {
                type: Object,
                name: "",
                description: "",
                required: true,
            },
        },
        data(){
            return{
                isEditing: false,
                canEdit: true,
                newDetails: {
                    name: "",
                    description: "",
                },
            }
        },
        methods: {
            startEditing(){
                this.newDetails.name = this.board.name
                this.newDetails.description = this.board.description
                this.isEditing = true
            },
            finishEditing(){
                this.isEditing = false
            },
            updateBoardDetails(){
                const oldName = this.board.name
                const oldDescription = this.board.description
                this.board.name = this.newDetails.name
                this.board.description = this.newDetails.description
                this.isEditing = false
                // this.$emit("updateListLocally", this.list)
                axios.jigo.put(`/v2/path/integrations/boards/updateBoardDetails/${this.board.JID}`, this.newDetails)
                .then((resp) => {
                    if(resp.status !== 200 && resp.status !== 204) {
                        console.error("ERROR: unwanted status returned", resp.status)
                        this.board.name = oldName
                        this.board.description = oldDescription
                        // this.$emit("updateListTitleLocally", this.list)
                    }
                })
                .catch((err) => {
                    this.board.name = oldName
                    this.board.description = oldDescription
                    // this.$emit("updateListLocally", this.list)
                    console.error("ERROR: updateListTitle FAILED", err)
                })

            },
            cancelUpdateBoardDetails(){
                this.isEditing = false
            }
        }
    }
</script>

<style scoped>

</style>
