<template>
    <div class="relative py-6 px-4 sm:px-6  lg:py-10 lg:px-8">
        <div class="relative max-w-7xl mx-auto">
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="bg-indigo-50 rounded-md">
                <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
                    <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
                        <span class="block">Manage information in Boards</span>
                        <span class="block text-indigo-600">Enjoy the power of Kanban</span>
                    </h2>
                    <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div class="inline-flex rounded-md shadow">
                            <button @click="newBoardCreation(true)" href="#" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                                Create a new board
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-start mt-10">
                <h2 class="text-3xl tracking-tight font-extrabold text-gray-100 sm:text-4xl">
                    Boards
                </h2>
            </div>

            <div class="mt-4 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                <new-board-card :isCreatingNewBoard="isCreatingNewBoard"
                                @newBoardCreation="newBoardCreation" @createNewBoard="createNewBoard"
                                :class="{'row-span-3': isCreatingNewBoard, 'row-span-1': !isCreatingNewBoard}"></new-board-card>

                <board-card v-for="board in boards" :key="board.JID" :board="board"> </board-card>

            </div>
        </div>
    </div>
</template>

<script>
import BoardCard from "@/pages/communityPage/components/BoardCard";
import NewBoardCard from "@/pages/communityPage/components/NewBoardCard";
import {mapState} from 'vuex'
import * as axios from "@/axios-auth";
export default {
    name: "CommunityBoardsMain",
    components: {NewBoardCard, BoardCard},
    props: {
        communityOwner: {
            type: String,
            required: true,
        },
        communityName: {
            type: String,
            required: true,
        },
    },
    data(){
        return {
            isCreatingNewBoard: false,
        }
    },
    created(){
    },
    computed: {
        ...mapState({
            boards: state => state.community.boards,
        }),
    },
    methods: {
        newBoardCreation(val) {
            this.isCreatingNewBoard = val
        },
        createNewBoard(board){
            let loader = this.$loading.show({
                onCancel: this.loaderCanceled,
            });
            axios.jigo.post(`/v2/community/integrations/boards/newBoard/${this.communityOwner}/${this.communityName}`, board)
            .then( (resp) => {
                if (resp.status !== 200) {
                    console.log("Unwanted status: ", resp.status, resp)
                    loader.hide()
                    return
                }
                this.isCreatingNewBoard = false
                const board = resp.data
                this.$store.dispatch("addBoard", board)
                loader.hide()
                this.$router.push({name: 'communityBoard', params: {boardID: board.JID}})
            }).catch((err) => {
                loader.hide()
                console.error("ERROR: updateUsername FAILED", err)
            })
        }
    }
}
</script>

<style scoped>

</style>
