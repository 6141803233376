<template>
    <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div @click="newBoardPressed" v-if="!isCreatingNewBoard" class="bg-white overflow-hidden shadow rounded-lg cursor-pointer group ">
            <div class="px-2 py-3 sm:p-4">
                <!-- This example requires Tailwind CSS v2.0+ -->
                <div class="bg-gray-200 group-hover:bg-gray-300 overflow-hidden shadow rounded-lg">
                    <div class="px-2 py-3 sm:p-4 flex justify-center text-jiruto-zotDarker">
                        <svg class="h-12 y-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <!-- Content goes here -->
                    </div>
                </div>

                <!-- Content goes here -->
            </div>
        </div>

        <div v-if="isCreatingNewBoard" class="bg-white overflow-hidden shadow rounded-lg cursor-pointer group ">
            <div class="px-2 py-3 sm:p-4">
                <!-- This example requires Tailwind CSS v2.0+ -->

                <div class="space-y-6 sm:space-y-5">

                        <div class="sm:col-span-4">
                            <label for="newBoardName" class="block text-sm font-medium text-gray-700">
                                Board Name
                            </label>
                            <div class="mt-1 flex rounded-md shadow-sm">
                                <input v-model="newBoard.name" type="text" name="newBoardName" id="newBoardName" autocomplete="username" class="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300">
                            </div>
                        </div>

                        <div class="sm:col-span-6">
                            <label for="newBoardDescription" class="block text-sm font-medium text-gray-700">
                                Description
                            </label>
                            <div class="mt-1">
                                <textarea v-model="newBoard.description" id="newBoardDescription" name="newBoardDescription" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
                            </div>
                            <p class="mt-2 text-sm text-gray-500">Write a short description of your new board (optional)</p>
                        </div>


                    <div class="mt-8 border-gray-200 pt-5">
                        <div class="flex justify-end">
                                <span class="inline-flex rounded-md shadow-sm">
                                    <button type="button" @click="cancelNewBoard" class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                                      Cancel
                                    </button>
                                </span>
                            <span class="ml-3 inline-flex rounded-md shadow-sm">
                                    <button @click="createBoard" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                      Create Board
                                    </button>
                                </span>
                        </div>
                    </div>
                </div>

                <!-- Content goes here -->
            </div>
        </div>



    </div>
</template>

<script>
    export default {
        name: "NewBoardCard",
        props: ['isCreatingNewBoard'],
        data(){
            return {
                newBoard: {
                    name: "",
                    description: "",

                }
            }
        },
        methods: {
            newBoardPressed(){
                this.$emit("newBoardCreation", true)
            },
            createBoard(){
                this.$emit("createNewBoard", this.newBoard)
            },
            cancelNewBoard(){
                this.$emit("newBoardCreation", false)
            }
        }
    }
</script>

<style scoped>

</style>
